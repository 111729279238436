import { BASE_URL } from "../config";
import { callApi } from "../utils";

// iNITIALIZATION
export const initializationService = async () => {
  const url = `${BASE_URL}/api/v2/auth/init`;
  const json = await callApi(url, "GET", "", null);
  return json;
};

// NEXT
export const nextService = async (requestData) => {
  const url = `${BASE_URL}/api/v2/auth/next`;
  const json = await callApi(url, "POST", "", requestData);
  return json;
};
