// Third-party
import { combineReducers } from "@reduxjs/toolkit";
// Functionals reducers
import CustomizerReducer from "../../redux/customizer/CustomizerReducer";

// Importing slices

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  CustomizerReducer,
});

export default reducers;
