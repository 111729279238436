import React, { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/index.js";
import Spinner from "./components/spinner/Spinner.js";
import { initializationService, nextService } from "./apis/bootstrap-services";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "./assets/global/global.css";

const initialization = async () => {

  try {

    let result = await initializationService();
    localStorage.setItem("session", result.init);
    let publickey = await nextService({ data: "string" });
    localStorage.setItem("out", publickey.access_token);
    const App = lazy(() => import("./App"));

    const root = ReactDOM.createRoot(document.getElementById("root"));

    root.render(
      <React.StrictMode>
        <ReduxProvider store={store}>
          <Suspense fallback={<Spinner />}>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </Suspense>
        </ReduxProvider>
      </React.StrictMode>
    );

  } catch (error) {

    console.error(error);
    
  }

};

initialization();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
